import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {KeyServicesSection,KeyServicesRight,
    KeyServicesCol1,KeyServicesCol2,
    KeyServicesIcon,
    KeyServicesHeading,
    KeyServicesCustomContainer,
    KeyServicesRightInnerRow,
    KeyServicesDesc} from './keyservices.style'
import {SectionHeading} from '../Common/common.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

const KeyServices = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            interior2Json {
                KeyServices {
                    DelayConstant
                    SectionHeading
                    KeyServicesLeftImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    KeyServicesCustomContainer{
                        KeyServicesIcon
                        KeyServicesHeading
                        KeyServicesDesc
                    }
                }
            }
        }
    `);
    return (
        <KeyServicesSection id="servicesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.interior2Json.KeyServices.DelayConstant}>
                            <SectionHeading>{JSONData.interior2Json.KeyServices.SectionHeading}</SectionHeading>
                        </Fade>
                    </Col>
                </Row>

                <Fade bottom delay={1*JSONData.interior2Json.KeyServices.DelayConstant}>
                    <KeyServicesCustomContainer>
                        <KeyServicesRight>
                            <Col lg="4" md="12" sm="12">
                                <GatsImg 
                                    fluid={JSONData.interior2Json.KeyServices.KeyServicesLeftImage.childImageSharp.fluid} 
                                    className="KeyServicesLeftImage"
                                    alt=""
                                />
                            </Col>
                            <Col lg="8" md="12" sm="12">
                                <KeyServicesRightInnerRow>
                                    <Col md="6">
                                        <KeyServicesCol1>
                                            <KeyServicesIcon src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[0].KeyServicesIcon} alt=""/>
                                            <KeyServicesHeading>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[0].KeyServicesHeading}</KeyServicesHeading>
                                            <KeyServicesDesc>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[0].KeyServicesDesc}</KeyServicesDesc>
                                        </KeyServicesCol1>
                                    </Col>
                                    <Col md="6">
                                        <KeyServicesCol2>
                                            <KeyServicesIcon src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[1].KeyServicesIcon} alt=""/>
                                            <KeyServicesHeading>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[1].KeyServicesHeading}</KeyServicesHeading>
                                            <KeyServicesDesc>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[1].KeyServicesDesc}</KeyServicesDesc>
                                        </KeyServicesCol2>
                                    </Col>
                                    <Col md="6">
                                        <KeyServicesCol1>
                                            <KeyServicesIcon src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[2].KeyServicesIcon} alt=""/>
                                            <KeyServicesHeading>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[2].KeyServicesHeading}</KeyServicesHeading>
                                            <KeyServicesDesc>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[2].KeyServicesDesc}</KeyServicesDesc>
                                        </KeyServicesCol1>
                                    </Col>
                                    <Col md="6">
                                        <KeyServicesCol2>
                                            <KeyServicesIcon src={JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[3].KeyServicesIcon} alt=""/>
                                            <KeyServicesHeading>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[3].KeyServicesHeading}</KeyServicesHeading>
                                            <KeyServicesDesc>{JSONData.interior2Json.KeyServices.KeyServicesCustomContainer[3].KeyServicesDesc}</KeyServicesDesc>
                                        </KeyServicesCol2>
                                    </Col>
                                </KeyServicesRightInnerRow>
                            </Col>
                        </KeyServicesRight>
                    </KeyServicesCustomContainer>
                </Fade>
            </Container>
        </KeyServicesSection>
    );
}

export default KeyServices;
